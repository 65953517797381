import * as React from "react"
import { PageProps } from "gatsby"
import {
  Text,
  Link,
  MonogramIcon,
  useTheme,
  EmptyState,
  EmptyStateSecondaryAction,
  ThemeCss,
} from "gatsby-interface"
import {
  IndicatorWrapper,
  GatsbyIndicatorButton,
  infoIcon,
  linkIcon,
  IndicatorButton,
} from "@gatsby-cloud-pkg/gatsby-plugin-preview"
import Loading from "@modules/ui/components/Loading"

import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { previewLoader as text } from "@modules/locales/default"
import { useTracker } from "@modules/analytics"

import { useNodeManifestRedirect } from "./use-node-manifest-redirect"

import { errorMessages } from "./use-node-manifest-redirect/move-to-gatsby-plugin-gatsby-cloud/use-poll-for-node-manifest"

const { useEffect, Fragment } = React

const buildStatusToUIMessageMap = {
  QUEUED: "Kicking off your build...",
  BUILDING: "Building your preview...",
  UPLOADING: "Deploying...",
}

export type ContentLoaderProps = {
  manifestId: string
  siteId: string
  sourcePluginName: string
  contentId?: string
} & PageProps

export function ContentLoader(props: ContentLoaderProps) {
  const { trackPageViewed } = useTracker()

  const { manifestId, siteId, sourcePluginName, contentId } = props

  const {
    hookIsMisconfigured,
    errorMessage,
    organizationId,
    previewUrl,
    previewBuildStatus,
  } = useNodeManifestRedirect({
    manifestId,
    siteId,
    sourcePluginName,
    contentId,
  })

  useEffect(function onMountCheckForErrors() {
    if (hookIsMisconfigured) {
      console.error(
        `Missing part of content loader path. Please format your path like so /content-sync/:siteId/:sourcePluginName/:manifestId. See https://gatsby.dev/content-sync-plugin-author for more info.`
      )
    } else {
      trackPageViewed(`Content Sync`, siteId, sourcePluginName)
    }
  }, [])

  return (
    <Fragment>
      {siteId && !hookIsMisconfigured ? (
        <IndicatorWrapper loaded>
          <GatsbyIndicatorButton />
          <IndicatorButton
            {...{ clickable: false, disabled: true, icon: infoIcon }}
          />
          <IndicatorButton
            {...{ clickable: false, disabled: true, icon: linkIcon }}
          />
        </IndicatorWrapper>
      ) : null}
      {errorMessage ? (
        <ErrorContent
          errorText={errorMessage}
          siteUrl={previewUrl}
          orgId={organizationId}
          siteId={siteId}
          sourcePluginName={sourcePluginName}
        />
      ) : (
        <LoadingContent
          message={
            buildStatusToUIMessageMap?.[
              previewBuildStatus as keyof typeof buildStatusToUIMessageMap
            ] || null
          }
        />
      )}
    </Fragment>
  )
}

export const footerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  width: `100%`,
  backgroundColor: theme.colors.ui.background,
})

export const errorContentCss: ThemeCss = () => ({
  display: `flex`,
  flexDirection: `column`,
  width: `100vw`,
  height: `100vh`,
})

export const graphicWrapperCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.ui.background,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  flex: 1,
})

export const baseLinkCss: ThemeCss = () => ({
  textDecoration: `none`,
  margin: `0 0 0 7px`,
  cursor: `pointer`,
  alignItems: `flex-start`,
  "&:hover": {
    textDecoration: `none`,
  },
  "& > svg": {
    margin: `auto auto auto 5px`,
  },
})

function Footer() {
  const { colors } = useTheme()

  return (
    <div css={footerCss}>
      <Text size="S">Powered by</Text>
      <Link href={`https://gatsbyjs.com`} css={baseLinkCss}>
        <MonogramIcon
          color={colors.gatsby}
          style={{ marginTop: 0, marginRight: 5 }}
        />
        <Text variant="EMPHASIZED">Gatsby&nbsp;</Text>
        <Text tone="BRAND">Cloud</Text>
      </Link>
    </div>
  )
}

/**
 * Main content (image + text) if a build is in progress
 */
function LoadingContent({ message }: { message: string | null }) {
  const { colors } = useTheme()
  return (
    <Fragment>
      <Loading
        message={message || text.messages.loading}
        style={{ backgroundColor: colors.ui.background }}
      />
      <Footer />
    </Fragment>
  )
}

/**
 * Main content (image + text) if a build has errored
 */
function ErrorContent({
  siteUrl,
  errorText,
  orgId,
  siteId,
  sourcePluginName,
}: {
  siteUrl?: string | null
  errorText?: string
  orgId?: string
  siteId?: string
  sourcePluginName?: string
}) {
  const { trackAction } = useTracker()

  useEffect(() => {
    trackAction({
      eventType: `TRACK_EVENT`,
      name: `Content Sync Error`,
      uiSource: `Content Sync`,
      siteId,
      pluginName: sourcePluginName,
    })
  }, [])

  // default error link
  const errorLink: { url: string; text: string } = {
    url:
      // if we have these
      orgId && siteId
        ? // we can send them to their site build list
          `${process.env.GATSBY_DASHBOARD_URL}/dashboard/${orgId}/sites/${siteId}/cmsPreview`
        : // otherwise send them to the dashboard
          `${process.env.GATSBY_DASHBOARD_URL}/dashboard`,

    text: orgId && siteId ? `View Error Logs` : `View Gatsby Cloud Dashboard`,
  }

  // override the default error link if there was no page found for the content the user is trying to view.
  if (siteUrl && errorText === errorMessages.noPageFound) {
    errorLink.url = siteUrl
    errorLink.text = `View Homepage`
  }

  return (
    <div css={errorContentCss}>
      <div css={graphicWrapperCss}>
        <EmptyState
          heading={text.headers.error}
          text={errorText}
          graphic={<EmptyStateGraphic />}
          secondaryAction={
            <EmptyStateSecondaryAction href={errorLink.url}>
              {errorLink.text}
            </EmptyStateSecondaryAction>
          }
        />
      </div>
      <Footer />
    </div>
  )
}
