// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type ContentLoaderInfoQueryVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
}>;


export type ContentLoaderInfoQuery = (
  { __typename?: 'Query' }
  & { contentLoaderInfo?: Types.Maybe<(
    { __typename?: 'ContentLoaderInfo' }
    & Pick<Types.ContentLoaderInfo, 'siteExists' | 'previewBuildStatus' | 'previewBuildsEnabled' | 'latestBuildStatus' | 'previewUrl' | 'stableBuildURL' | 'orgId'>
  )> }
);


export const ContentLoaderInfoDocument = gql`
    query ContentLoaderInfo($siteId: UUID!) {
  contentLoaderInfo(siteId: $siteId) {
    siteExists
    previewBuildStatus
    previewBuildsEnabled
    latestBuildStatus
    previewUrl
    stableBuildURL
    orgId
  }
}
    `;

/**
 * __useContentLoaderInfoQuery__
 *
 * To run a query within a React component, call `useContentLoaderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentLoaderInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentLoaderInfoQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useContentLoaderInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentLoaderInfoQuery, ContentLoaderInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentLoaderInfoQuery, ContentLoaderInfoQueryVariables>(ContentLoaderInfoDocument, baseOptions);
      }
export function useContentLoaderInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentLoaderInfoQuery, ContentLoaderInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentLoaderInfoQuery, ContentLoaderInfoQueryVariables>(ContentLoaderInfoDocument, baseOptions);
        }
export type ContentLoaderInfoQueryHookResult = ReturnType<typeof useContentLoaderInfoQuery>;
export type ContentLoaderInfoLazyQueryHookResult = ReturnType<typeof useContentLoaderInfoLazyQuery>;
export type ContentLoaderInfoQueryResult = ApolloReactCommon.QueryResult<ContentLoaderInfoQuery, ContentLoaderInfoQueryVariables>;
