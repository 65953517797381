import * as React from "react"

import { ContentLoaderInfo } from "./move-to-gatsby-plugin-gatsby-cloud/use-poll-for-node-manifest/types"

import { useContentLoaderInfoQuery } from "@modules/graphql/queries.generated"

import {
  usePollForNodeManifest,
  errorMessages as nodeManifestErrorMessages,
} from "./move-to-gatsby-plugin-gatsby-cloud/use-poll-for-node-manifest"

import { DEBUG_CONTENT_SYNC_MODE } from "./move-to-gatsby-plugin-gatsby-cloud/use-poll-for-node-manifest/constants"

import { doesUrlExist } from "./move-to-gatsby-plugin-gatsby-cloud/use-poll-for-node-manifest/utils"
import { useTracker } from "@modules/analytics"

export const errorMessages = {
  ...nodeManifestErrorMessages,
  hookIsMisconfigured: `Your URL has been misconfigured. Ask a developer to check the console logs for more info.`,
  previewsNotEnabled: `Your Gatsby site does not have previews enabled.`,
}

const { useEffect, useState } = React

/**
 * Checks that all preconditions for finding node manifest files are met and returns contentLoaderInfo from GraphQL Gateway
 */
const useHandlePreconditionsAndReturnContentLoaderInfo = ({
  siteId,
  hookIsMisconfigured,
  setErrorMessage,
  setShowError,
}: {
  siteId: string
  hookIsMisconfigured: boolean
  setErrorMessage: (arg: string) => void
  setShowError: (arg: boolean) => void
}) => {
  const [checkForPreviewEnabled, setCheckForPreviewEnabled] = useState(true)

  const {
    data: apolloData,
    error: apolloError,
    refetch: refetchApolloData,
  } = useContentLoaderInfoQuery({
    variables: {
      siteId,
    },
    skip: hookIsMisconfigured,
  })

  if (
    checkForPreviewEnabled &&
    apolloData?.contentLoaderInfo?.previewBuildsEnabled === false
  ) {
    console.error(
      `Preview builds are not enabled for this site. See docs for more info.`
    )
    setShowError(true)
    setErrorMessage(errorMessages.previewsNotEnabled)
    setCheckForPreviewEnabled(false)
  }

  useEffect(
    function handleApolloErrors() {
      if (apolloError) {
        console.info(`Encountered apollo error`, apolloError)
        setShowError(true)
      }
    },
    [apolloError]
  )

  useEffect(function onMountCheckForErrors() {
    if (hookIsMisconfigured) {
      setShowError(true)
      setErrorMessage(errorMessages.hookIsMisconfigured)
    }
  }, [])

  return {
    contentLoaderInfo: apolloData?.contentLoaderInfo as
      | ContentLoaderInfo
      | undefined,
    hookIsMisconfigured,
    refetchApolloData,
  }
}

const eagerRedirectsStartTime = Date.now()

/**
 * @name useNodeManifestRedirect
 * @description This hook does 7 things (not in this order):
 * 1. handles GraphQL Gateway errors and Gatsby function network errors when searching for node manifest files
 * 2. tracks the time since the site's preview build queue went idle. After it's been idle too long and no manifest file is found it times out and returns an error message
 * 3. polls for node manifest files and then returns a redirect url or alternatively an error message explaining that no page was found for the content being previewed
 * 4. Checks for Content Sync support in the Gatsby site by examining the installed packages via GraphQL Gateway
 * 5. Checks to ensure Preview builds are enabled for this site
 * 6. Records and returns the amount of time it took from page load until a manifest file was found
 * 7. Returns the organization ID for the given site id
 */
export const useNodeManifestRedirect = ({
  manifestId,
  siteId,
  sourcePluginName,
  contentId,
}: {
  manifestId: string
  siteId: string
  sourcePluginName: string
  contentId: string | undefined
}) => {
  const localStorageEagerRedirectId = contentId
    ? `eager-redirect-id--${siteId}-${sourcePluginName}-${contentId}`
    : null

  const { trackAction } = useTracker()

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(errorMessages.default)

  const hookIsMisconfigured = !manifestId || !siteId || !sourcePluginName

  useEffect(() => {
    if (!DEBUG_CONTENT_SYNC_MODE) {
      console.info(
        `Append ?debug=true to the URL to enter debug mode and see additional Content Sync logging.`
      )
    }
  }, [])

  const { contentLoaderInfo, refetchApolloData } =
    useHandlePreconditionsAndReturnContentLoaderInfo({
      siteId,
      hookIsMisconfigured,
      setErrorMessage,
      setShowError,
    })

  const {
    redirectUrl,
    loadingDuration,
    errorMessage: pollingErrorMessage,
  } = usePollForNodeManifest({
    contentLoaderInfo,
    manifestId,
    sourcePluginName,
    siteId,
    shouldPoll: !hookIsMisconfigured && !showError,
    pollCallback: refetchApolloData,
  })

  useEffect(() => {
    if (pollingErrorMessage) {
      setErrorMessage(pollingErrorMessage)
      setShowError(true)
    }
  }, [pollingErrorMessage])

  const [eagerRedirectTryCount, triggerEagerRedirectRetry] = React.useState(0)
  const [eagerRedirectUrl, setEagerRedirectUrl] = React.useState<string>()

  useEffect(
    function onMountHandleEagerRedirect() {
      const redirectUrl =
        localStorageEagerRedirectId &&
        localStorage.getItem(localStorageEagerRedirectId)

      if (
        // We've already been here and stored a redirect url from a previous Content Sync run.
        redirectUrl
      ) {
        // Check if the url still exists or if it's a 404
        // If it's a 404, the previous redirectUrl might not exist because the content might've moved to a new page.
        doesUrlExist(redirectUrl)
          .then(redirectUrlExists => {
            if (redirectUrlExists) {
              const contentSyncEncodedParam = window.btoa(
                JSON.stringify({
                  mid: manifestId,
                  plgn: sourcePluginName,
                })
              )

              setEagerRedirectUrl(
                window.encodeURI(
                  `${redirectUrl}?csync=${contentSyncEncodedParam}${
                    DEBUG_CONTENT_SYNC_MODE ? `&debug=true` : ``
                  }`
                )
              )
            }
            // if the content moved to a new page, we don't know where that is yet so we need to wait for regular content sync to run it's course. We do that by not redirecting early and removing the eager redirect ID from localstorage
            else if (localStorageEagerRedirectId) {
              localStorage.removeItem(localStorageEagerRedirectId)

              if (DEBUG_CONTENT_SYNC_MODE) {
                console.info(
                  `Couldn't eagerly redirect to ${redirectUrl} because it no longer exists. Removing eager redirect key for ${localStorageEagerRedirectId} content ID.`
                )
              }
            }
          })
          // retry when doesUrlExist(redirectUrl) throws errors.
          .catch(e => {
            if (eagerRedirectTryCount < 5) {
              console.error(e, `Retrying`)
            } else {
              console.info(`Retried 5 times`)
              return
            }

            setTimeout(
              () => triggerEagerRedirectRetry(eagerRedirectTryCount + 1),
              eagerRedirectTryCount * 1000 + 1000
            )
          })
      }
    },
    [eagerRedirectTryCount]
  )

  useEffect(
    function handleRedirect() {
      if (redirectUrl) {
        trackAction({
          eventType: `TRACK_EVENT`,
          name: `Content Sync Success`,
          uiSource: `Content Sync`,
          siteId: siteId || "",
          duration: loadingDuration,
          pluginName: sourcePluginName,
        })

        if (contentId && localStorageEagerRedirectId) {
          localStorage.setItem(localStorageEagerRedirectId, redirectUrl)

          if (DEBUG_CONTENT_SYNC_MODE) {
            console.info(
              `Storing redirectUrl ${redirectUrl} with content id ${localStorageEagerRedirectId} to eagerly redirect on the next preview for this content id.`
            )
          }
        }

        console.info(`redirecting to ${redirectUrl} after ${loadingDuration}ms`)
        window.location.replace(redirectUrl + `?gt=${loadingDuration}`)
      } else if (eagerRedirectUrl) {
        const eagerRedirectsDuration = Date.now() - eagerRedirectsStartTime

        trackAction({
          eventType: `TRACK_EVENT`,
          name: `Content Sync Eager Redirect Success`,
          uiSource: `Content Sync`,
          siteId: siteId || "",
          duration: eagerRedirectsDuration,
          pluginName: sourcePluginName,
        })

        if (DEBUG_CONTENT_SYNC_MODE) {
          console.info(`Eagerly redirecting to ${redirectUrl}`)
        }

        window.location.replace(eagerRedirectUrl)
      }
    },
    [redirectUrl, eagerRedirectUrl]
  )

  return {
    errorMessage: showError && !eagerRedirectUrl ? errorMessage : undefined,
    hookIsMisconfigured,
    redirectUrl,
    loadingDuration,
    organizationId: contentLoaderInfo?.orgId as string | undefined,
    previewUrl: contentLoaderInfo?.previewUrl,
    previewBuildStatus: contentLoaderInfo?.previewBuildStatus as
      | string
      | undefined,
  }
}
